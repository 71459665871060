<template>
  <section class="row">
    <div class="col-12 col-lg-7 mb-16">
      <v-card class="p-16 py-md-32 p-lg-32">
        <div class="row">
          <div
              v-for="(checker) in filteredCheckers"
              :key="checker.name"
              class="col-12 col-md-6 mb-16"
          >
            <div class="row no-gutters mb-4 align-items-center">
              <div class="col-auto">
                <template v-if="checker.progress >= 100">
                  <v-icon
                      v-if="checker.status === CHECKER_VALIDATION_STATUS.ERROR"
                      class="mr-4 display-block"
                      fill="danger"
                      name="close"
                      size="10"
                  />

                  <span
                      v-else-if="checker.status === CHECKER_VALIDATION_STATUS.WARNING"
                      class="text-color-warning pr-8"
                  >
                      !
                    </span>

                  <v-icon
                      v-else
                      class="mr-4 display-block"
                      stroke="primary"
                      name="checked"
                      size="10"
                  />
                </template>
              </div>
              <div class="col">
                {{ checker.title || $t(`processing.check.checker.${checker.name}.title`) }}
              </div>
            </div>

            <v-progress-linear
                :value="checker.progress"
                :error="checker.isError"
                :color="checker.status"
                height=".5rem"
            />
          </div>
        </div>
      </v-card>

      <div class="mt-32 d-none d-lg-block">
        <v-button
            :disabled="nextBtnIsDisabled"
            @click="send"
        >
          {{ $t('processing.check.message.continue') }}
        </v-button>
      </div>
    </div>

    <div class="col-12 col-lg-5 mb-16">
      <div class="c-align-vertical">
        <div class="c-align-vertical__main">
          <v-card class="p-16 py-md-32 p-lg-32 h-100">
            <div
                class="text-center"
                v-if="!isChecked"
            >
              <p class="text-bold">
                {{ $t('processing.check.message.checking') }}
              </p>

              <v-progress-circular
                  class="mt-40"
                  :value="30"
                  :size="48"
                  indeterminate
              />
            </div>

            <div
                v-else-if="hasErrors"
                class="text-center"
            >
              <p class="text-bold">
                {{ $t('processing.check.message.error') }}
              </p>

              <v-icon
                  class="my-32"
                  fill="danger"
                  name="wrench"
                  size="48"
              />
            </div>

            <div
                class="text-center"
                v-else-if="hasWarnings"
            >
              <p class="text-bold">
                {{ $t('processing.check.message.warning') }}
              </p>

              <v-icon
                  class="my-16"
                  fill="warning"
                  name="warning"
                  size="64"
              />
            </div>

            <div
                v-else
                class="text-center"
            >
              <p class="text-bold">
                {{ $t('processing.check.message.success') }}
              </p>

              <v-icon
                  class="my-16"
                  fill="primary"
                  name="device-checked"
                  size="48"
              />
            </div>

            <template v-if="isChecked && (hasErrors || hasWarnings) ">
              <hr>

              <div
                  v-for="(error) in filteredErrors"
                  :key="error.name"
                  class="row my-16"
              >
                <div class="col-auto text-color-danger">*</div>
                <div class="col">
                  <p
                      class="with-colored-links"
                      v-html="error.errorMessage || $t(`processing.check.checker.${error.name}.error`)"
                  />
                </div>
              </div>

              <div
                  v-for="(warning) in filteredWarnings"
                  :key="warning.name"
                  class="row my-16"
              >
                <div class="col-auto text-color-warning">*</div>
                <div class="col">
                  <p
                      class="with-colored-links"
                      v-html="warning.errorMessage || $t(`processing.check.checker.${warning.name}.warning`)"/>
                </div>
              </div>
            </template>
          </v-card>
        </div>

        <div class="c-align-vertical__button mt-32">
          <v-button
              :disabled="nextBtnIsDisabled"
              @click="send"
          >
            {{ $t('processing.check.button.next') }}
          </v-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Bowser from 'bowser'
import { sendCheckApi, sendTelemetryApi } from '@/api'
import VCard from '@components/base/VCard'
import VButton from '@components/base/VButton'
import VProgressLinear from '@components/base/VProgressLinear'
import VProgressCircular from '@components/base/VProgressCircular'
import { measureSpeedAndPing, detectAdBlock } from '@utils/helpers'
import { VIcon } from '@components/base'

const CHECKER_VALIDATION_STATUS = {
  SUCCESS: 'primary',
  WARNING: 'warning',
  ERROR: 'danger'
}

export default {
  name: 'ProcessingCheck',

  components: {
    VIcon,
    VProgressCircular,
    VProgressLinear,
    VButton,
    VCard
  },

  inject: ['service'],

  props: {
    content: Object
  },

  data () {
    return {
      CHECKER_VALIDATION_STATUS,
      checkers: [
        {
          enabled: true,
          errorMessage: '',
          warningMessage: '',
          important: true, // close next button
          isChecked: false,
          progress: 0,
          name: 'device',
          title: '',
          status: CHECKER_VALIDATION_STATUS.SUCCESS,
          validator: deviceName => ['tablet', 'desktop'].includes(deviceName),
          optionsAllowed: null,
          optionsActual: null
        },
        {
          enabled: true,
          errorMessage: '',
          warningMessage: '',
          important: true,
          isChecked: false,
          status: CHECKER_VALIDATION_STATUS.SUCCESS,
          name: 'viewport',
          progress: 0,
          title: '',
          validator: (width, height) => (width >= 600 && height >= 480),
          optionsAllowed: null,
          optionsActual: null
        },
        {
          enabled: false,
          errorMessage: '',
          warningMessage: '',
          important: false,
          isChecked: false,
          progress: 0,
          name: 'adv',
          title: '',
          status: CHECKER_VALIDATION_STATUS.SUCCESS
        },
        {
          enabled: true,
          errorMessage: '',
          warningMessage: '',
          important: true,
          isChecked: false,
          status: CHECKER_VALIDATION_STATUS.SUCCESS,
          name: 'img',
          progress: 0,
          title: ''
        },
        {
          enabled: true,
          errorMessage: '',
          warningMessage: '',
          important: true,
          isChecked: false,
          status: CHECKER_VALIDATION_STATUS.SUCCESS,
          name: 'browser',
          progress: 0,
          title: '',
          validator: {
            chrome: '>=71',
            firefox: '>=65',
            opera: '>=58',
            safari: '>=14',
            yandex: '>=20',
            edge: '>=79'
          }
        },
        {
          enabled: true,
          errorMessage: '',
          warningMessage: '',
          important: true,
          isChecked: false,
          status: CHECKER_VALIDATION_STATUS.SUCCESS,
          name: 'speed',
          progress: 0,
          validator: ({ speed, ping }) => {
            if (speed < 256 || ping > 2000) {
              return CHECKER_VALIDATION_STATUS.ERROR
            } else if (speed < 512 || ping > 1000) {
              return CHECKER_VALIDATION_STATUS.WARNING
            }

            return CHECKER_VALIDATION_STATUS.SUCCESS
          },
          options: {
            imageUrl: 'https://cdn.linkis.pro/media/content/a8142332-4cb1-45bc-b012-948e5c59ea15/survey/check_internet_file.jpg',
            samples: 5
          },
          title: ''
        }
      ],
      browser: Bowser.getParser(window.navigator.userAgent)
    }
  },

  watch: {
    content () {
      this.fetchOptions()
    },

    isChecked (newValue) {
      if (newValue) {
        this.sendCheck()
      }
    }
  },

  mounted () {
    this.fetchOptions()

    this.filteredCheckers.forEach(checker => {
      const capitilizedFunctionName = `check${checker.name[0].toUpperCase()}${checker.name.slice(1)}`
      this[capitilizedFunctionName]()
    })
  },

  computed: {
    filteredCheckers () {
      return this.checkers.filter(checker => checker.enabled)
    },
    filteredErrors () {
      return this.filteredCheckers.filter(checker => checker.status === CHECKER_VALIDATION_STATUS.ERROR)
    },
    filteredWarnings () {
      return this.filteredCheckers.filter(checker => checker.status === CHECKER_VALIDATION_STATUS.WARNING)
    },
    isChecked () {
      return this.filteredCheckers.every(checker => checker.isChecked)
    },
    hasErrors () {
      return this.filteredErrors.length
    },
    hasWarnings () {
      return this.filteredWarnings.length
    },
    nextBtnIsDisabled () {
      const importantErrors = this.filteredErrors.some(checker => checker.important)
      return !(this.isChecked && !importantErrors)
    }
  },

  methods: {
    getChecker (checkerName) {
      return this.checkers.find(checker => checker.name === checkerName)
    },

    fetchOptions () {
      if (!Object.values(this.content).length) return

      const getValidation = (checkerName, options) => {
        switch (checkerName) {
          case 'device': {
            const allowedDevices = Object.entries(options).filter(([_key, value]) => value).map(([key]) => key)
            return deviceName => allowedDevices.includes(deviceName)
          }
          case 'viewport': {
            return (width, height) => (width >= options.width && height >= options.height)
          }
        }
      }

      Object.entries(this.content).forEach(([checkerName, values]) => {
        const checker = this.getChecker(checkerName)
        const { options, ...restValues } = values

        this.update(checker, restValues)

        if (options) {
          checker.optionsAllowed = options
          Object.assign(checker, { validator: getValidation(checkerName, options) })
        }
      })
    },

    checkDevice () {
      const deviceChecker = this.getChecker('device')
      const deviceName = this.browser.getPlatformType()

      deviceChecker.optionsActual = {
        desktop: deviceName === 'desktop',
        mobile: deviceName === 'mobile',
        tablet: deviceName === 'tablet'
      }

      if (!deviceChecker.validator(deviceName)) {
        this.update(deviceChecker, { status: CHECKER_VALIDATION_STATUS.ERROR })
      }

      this.update(deviceChecker, { progress: 100, isChecked: true })
    },

    checkViewport () {
      const viewportChecker = this.getChecker('viewport')
      const viewport = window.visualViewport
          ? window.visualViewport
          : { width: window.innerWidth, height: window.innerHeight }

      viewportChecker.optionsActual = {
        height: Math.ceil(viewport.height),
        width: Math.ceil(viewport.width)
      }

      /**
       * setTimeout здесь для проверки viewport при открытии во iframe
       * так как происходит пересчёт высоты и проверка отрабатывает не корректно
       */
      setTimeout(() => {
        if (!viewportChecker.validator(viewport.width, viewport.height)) {
          this.update(viewportChecker, { status: CHECKER_VALIDATION_STATUS.ERROR })
        }

        this.update(viewportChecker, { progress: 100, isChecked: true })
      }, 300)
    },

    async checkSpeed () {
      const speedChecker = this.getChecker('speed')

      try {
        const measurements = await measureSpeedAndPing(speedChecker.options, (progress) => {
          this.update(speedChecker, { progress })
        })
        const status = speedChecker.validator(measurements)

        if (status === CHECKER_VALIDATION_STATUS.ERROR) {
          await this.sendTelemetry({
            session_uuid: this.service.event,
            state: { slow_internet_speed: true }
          })
        }

        this.update(speedChecker, {
          progress: 100,
          isChecked: true,
          status
        })
      } catch (error) {
        console.error(error.message)
      }
    },

    checkAdv () {
      const advChecker = this.getChecker('adv')

      detectAdBlock()
          .then((hasAdBlocker) => {
            this.update(advChecker, {
              progress: 100,
              isChecked: true,
              status: hasAdBlocker
                  ? CHECKER_VALIDATION_STATUS.WARNING
                  : CHECKER_VALIDATION_STATUS.SUCCESS
            })
          })
    },

    checkImg () {
      const imgChecker = this.getChecker('img')
      const self = this
      const image = new Image()
      const timer = setTimeout(() => {
        self.update(imgChecker, {
          progress: 100,
          status: CHECKER_VALIDATION_STATUS.ERROR,
          isChecked: true
        })
      }, 3000)

      image.onload = function () {
        if (this.width + this.height > 0) {
          clearTimeout(timer)
          self.update(imgChecker, { progress: 100, isChecked: true })
        }
      }

      image.src = require('@/assets/images/nologo.png')
    },

    checkBrowser () {
      const browserChecker = this.getChecker('browser')

      if (!this.browser.satisfies(browserChecker.validator)) {
        this.update(browserChecker, { status: CHECKER_VALIDATION_STATUS.ERROR })
      }

      this.update(browserChecker, { progress: 100, isChecked: true })
    },

    sendTelemetry (data) {
      let attemptCount = 2

      return new Promise((resolve, reject) => {
        (function trySend () {
          sendTelemetryApi(data)
              .then(() => resolve())
              .catch(err => {
                if (attemptCount) {
                  attemptCount--
                  trySend()
                } else {
                  reject(err)
                }
              })
        })()
      })
    },

    sendCheck () {
      const checkerStatusKeys = {
        primary: 'success',
        warning: 'warning',
        danger: 'error'
      }
      const check = this.filteredCheckers.reduce((result, { name, status, optionsAllowed, optionsActual }) => {
        return [
          ...result,
          {
            name,
            result: checkerStatusKeys[status],
            ...(optionsAllowed || optionsActual) && {
              params: {
                ...(optionsAllowed && { allowed: optionsAllowed }),
                ...(optionsActual && { actual: optionsActual })
              }
            }
          }
        ]
      }, [])

      sendCheckApi({
        session_uuid: this.service.event,
        check
      })
    },

    update (checker, options) {
      Object.assign(checker, options)
    },

    send () {
      this.$emit('send')
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables/index.scss";
@import "~bootstrap/scss/mixins/breakpoints";

.c-align-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__main {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  // по-другому решить не удалось
  &__button {
    @include media-breakpoint-up(lg) {
      visibility: hidden;
    }
  }
}

.with-colored-links {
  a {
    color: cl(primary);
  }
}
</style>
